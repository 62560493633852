/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/
@font-face {
  font-family: Titillium;
  font-weight: 200;
  src: url('/assets/fonts/Titillium-Regular.otf');
}
@font-face {
  font-family: Titillium;
  font-weight: bold;
  src: url('/assets/fonts/Titillium-Bold.otf');
}
/**
 * ! Main Template
 */
body {
  background-color: #eff2f3;
}
.hiddenElement {
  display: none;
}
.navbar {
  border-radius: 0px;
}
.rowPadding {
  padding-top: 10px;
}
.input-xs,
select.input-xs {
  height: 20px;
  line-height: 20px;
  padding: 1px 5px;
}
.input-mini {
  width: 60px;
}
.input-small {
  width: 90px;
}
.input-medium {
  width: 150px;
}
.input-large {
  width: 210px;
}
.input-xlarge {
  width: 270px;
}
.input-xxlarge {
  width: 530px;
}
.searchButton {
  background-color: #ffba00;
  color: white;
  padding-top: 5px;
}
.loginBox {
  background-color: #d9dee2;
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.navbar-fixed-top {
  border-width: 1px;
}
.navbar-fixed-top .navbar-collapse {
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 768px) {
  .navbar-fixed-top {
    position: static;
  }
}
.navbar-right {
  background-color: #eff2f3;
}
.navbar-right .glyphicon-user {
  color: #ffba00;
}
.navbar-right .signInLink {
  padding-left: 5px;
  color: #ffba00;
}
.navbar-collapse {
  max-height: 600px;
}
.navbar {
  font-family: Titillium;
  font-weight: 600;
}
.input-group-btn > .btn + .btn {
  margin-left: -5px;
}
.dropdown-menu {
  left: -75px;
}
.navbar-brand {
  padding: 0px;
}
.headerLogoText {
  padding-left: 15px;
  padding-top: 15px;
}
.headerLogoImage {
  max-width: 220px;
  max-height: 50px;
  margin-top: 0px;
  padding-top: 0px;
}
.sortBy {
  margin-top: 12px;
}
@media (max-width: 480px) {
  .sortBy {
    display: none;
  }
}
.collectionMenu {
  overflow: scroll;
  max-height: 90vh;
  width: 250px;
}
#search_concept {
  max-width: 120px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-menu li a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/** Footer **/
.footer {
  margin: 10px;
}
.universityFooter {
  margin-top: 10px;
  margin-bottom: 10px;
}
.elevatorFooterImage {
  height: 40px;
  margin-top: -10px;
}
.sortHover {
  cursor: move;
}
/**
 * ! Asset Entry
 */
.widgetHeaderRow span {
  font-weight: 500;
}
.ui-helper-hidden-accessible {
  display: none;
}
.parsley-success {
  color: #468847 !important;
  background-color: #dfeedf !important;
  border: 1px solid #d7f6d8 !important;
}
.parsley-error {
  color: #B94A48 !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}
.parsley-error-list {
  font-size: 11px;
  margin: 2px;
  list-style-type: none;
}
.parsley-error-list li {
  line-height: 11px;
}
.viewAsset {
  display: none;
}
.haveContent {
  display: none;
}
.templateSelector {
  display: inline;
}
.advancedContent {
  display: none;
}
.deleteFile {
  display: none;
}
.fileLabel {
  padding-top: 7px;
}
.ui-autocomplete {
  padding: 10px;
  width: 400px;
  border: 1px solid #bcbdff;
  background: white;
  display: block;
}
.ui-autocomplete li {
  margin: 0px;
  padding: 0px;
  font-size: 1.2em;
  list-style-type: none;
}
/**
 * ! left tabs
 */
.theme-0 {
  background-color: #d9dee2;
}
.theme-0 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-0 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-0 .tabs-left > li.active > a,
.theme-0 .tabs-left > li.active > a:hover,
.theme-0 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-0 .tabs-left > li.active > a:hover {
  background-color: #bcc5cc;
}
.theme-0 .tabs-left > li.active > a {
  background-color: #d9dee2;
  font-weight: bold;
}
.theme-0 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-0 .tabs-left > li > a:hover {
  background-color: #f6f7f8;
}
.theme-1 {
  background-color: #E4D2C6;
}
.theme-1 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-1 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-1 .tabs-left > li.active > a,
.theme-1 .tabs-left > li.active > a:hover,
.theme-1 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-1 .tabs-left > li.active > a:hover {
  background-color: #d4b7a3;
}
.theme-1 .tabs-left > li.active > a {
  background-color: #E4D2C6;
  font-weight: bold;
}
.theme-1 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-1 .tabs-left > li > a:hover {
  background-color: #f4ede9;
}
.theme-2 {
  background-color: #E1E6C8;
}
.theme-2 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-2 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-2 .tabs-left > li.active > a,
.theme-2 .tabs-left > li.active > a:hover,
.theme-2 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-2 .tabs-left > li.active > a:hover {
  background-color: #ced6a5;
}
.theme-2 .tabs-left > li.active > a {
  background-color: #E1E6C8;
  font-weight: bold;
}
.theme-2 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-2 .tabs-left > li > a:hover {
  background-color: #f4f6eb;
}
.theme-3 {
  background-color: #C3E5C2;
}
.theme-3 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-3 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-3 .tabs-left > li.active > a,
.theme-3 .tabs-left > li.active > a:hover,
.theme-3 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-3 .tabs-left > li.active > a:hover {
  background-color: #a0d69e;
}
.theme-3 .tabs-left > li.active > a {
  background-color: #C3E5C2;
  font-weight: bold;
}
.theme-3 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-3 .tabs-left > li > a:hover {
  background-color: #e6f4e6;
}
.theme-4 {
  background-color: #BDE5DD;
}
.theme-4 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-4 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-4 .tabs-left > li.active > a,
.theme-4 .tabs-left > li.active > a:hover,
.theme-4 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-4 .tabs-left > li.active > a:hover {
  background-color: #98d7ca;
}
.theme-4 .tabs-left > li.active > a {
  background-color: #BDE5DD;
  font-weight: bold;
}
.theme-4 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-4 .tabs-left > li > a:hover {
  background-color: #e2f3f0;
}
.theme-5 {
  background-color: #C6D8E4;
}
.theme-5 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-5 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-5 .tabs-left > li.active > a,
.theme-5 .tabs-left > li.active > a:hover,
.theme-5 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-5 .tabs-left > li.active > a:hover {
  background-color: #a3c0d4;
}
.theme-5 .tabs-left > li.active > a {
  background-color: #C6D8E4;
  font-weight: bold;
}
.theme-5 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-5 .tabs-left > li > a:hover {
  background-color: #e9f0f4;
}
.theme-6 {
  background-color: #C7C8E4;
}
.theme-6 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-6 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-6 .tabs-left > li.active > a,
.theme-6 .tabs-left > li.active > a:hover,
.theme-6 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-6 .tabs-left > li.active > a:hover {
  background-color: #a5a6d3;
}
.theme-6 .tabs-left > li.active > a {
  background-color: #C7C8E4;
  font-weight: bold;
}
.theme-6 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-6 .tabs-left > li > a:hover {
  background-color: #e9eaf5;
}
.theme-7 {
  background-color: #E1B9C2;
}
.theme-7 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-7 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-7 .tabs-left > li.active > a,
.theme-7 .tabs-left > li.active > a:hover,
.theme-7 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-7 .tabs-left > li.active > a:hover {
  background-color: #d295a3;
}
.theme-7 .tabs-left > li.active > a {
  background-color: #E1B9C2;
  font-weight: bold;
}
.theme-7 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-7 .tabs-left > li > a:hover {
  background-color: #f0dde1;
}
.theme-8 {
  background-color: #E9CFD7;
}
.theme-8 .tabs-left {
  border-bottom: none;
  padding-top: 2px;
}
.theme-8 .tabs-left > li {
  float: none;
  margin-bottom: 2px;
}
.theme-8 .tabs-left > li.active > a,
.theme-8 .tabs-left > li.active > a:hover,
.theme-8 .tabs-left > li.active > a:focus {
  border-right-color: transparent;
}
.theme-8 .tabs-left > li.active > a:hover {
  background-color: #d9acba;
}
.theme-8 .tabs-left > li.active > a {
  background-color: #E9CFD7;
  font-weight: bold;
}
.theme-8 .tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.theme-8 .tabs-left > li > a:hover {
  background-color: #f9f2f4;
}
.rightPane {
  padding-top: 10px;
  height: 100%;
}
.leftPane {
  background-color: white;
  padding-right: 0px;
}
.widgetContents {
  background-color: white;
}
.widgetContentsContainer {
  margin-left: 10px;
  margin-right: 20px;
}
.minipreviewContainer {
  margin-bottom: 10px;
  padding: 5px;
}
@media (max-width: 480px) {
  .rightPane .tab-content > .tab-pane {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .rightPane .tab-content > .tab-pane {
    width: 540px;
  }
}
@media only screen and (min-width: 992px) {
  .rightPane .tab-content > .tab-pane {
    width: 702px;
  }
}
@media only screen and (min-width: 1200px) {
  .rightPane .tab-content > .tab-pane {
    width: 855px;
  }
}
.bottomButton {
  margin-bottom: 10px;
}
/**
 * ! widget specific
 */
.tooltipRow {
  margin-left: -3px;
  margin-bottom: 6px;
}
.isPrimary {
  display: none;
}
.moveUpButton {
  display: none;
}
.moveDownButton {
  display: none;
}
.uploadProgress {
  display: none;
}
.imagePreview {
  display: none;
}
.autocompletePreview {
  display: none;
}
.autocompleteEdit {
  display: none;
}
.clearRelated {
  display: none;
}
.nestedAsset .clearRelated {
  display: block;
}
.cancelButton {
  display: none;
}
.removeButton {
  display: none;
  position: absolute;
  bottom: 30px;
}
#mapModalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mapWidget {
  height: 400px;
}
.gm-style-iw {
  width: 150px;
  height: 100px;
}
.autoCompleterBlock {
  padding: 10px;
  width: 400px;
  border: 1px solid #bcbdff;
  background: white;
  display: block;
}
.autoCompleterBlock ol {
  margin: 0px;
  padding: 0px;
  font-size: 1.2em;
  list-style-type: none;
}
.dateRange {
  display: none;
}
.relatedAsset img {
  max-height: 50px;
  margin-right: 5px;
}
.relatedAssetContainer .panel-title {
  line-height: normal;
  position: relative;
}
.relatedAssetContainer .panel-title .truncatedTitle {
  display: inline-block;
  width: 82%;
  white-space: nowrap;
  overflow: hidden;
  /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
}
.relatedAssetContainer .panel-title .expandRelated {
  display: inline;
}
.relatedAssetContainer .panel-title .btn {
  display: none;
  position: absolute;
  right: -10px;
  top: -2px;
  z-index: 10;
}
.relatedAssetContainer .panel-title:hover .btn {
  display: block;
}
.collapsedChild .btn {
  position: absolute;
  right: 30px;
  top: 0px;
  z-index: 10;
  display: none;
}
.collapsedChild:hover .btn {
  display: block;
}
/**
 * ! Asset Display
 */
.searchResultsNavBar {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e1e7e9;
}
.assetWidget {
  margin-top: 5px;
  margin-bottom: 5px;
}
.assetWidget > ul {
  margin: 0;
  list-style-type: none;
  display: inline-block;
  padding-left: 0px;
}
.assetWidget > ul > li {
  display: inline;
}
.assetWidget > ul:not(.fileList) > li:after {
  content: ", ";
}
.assetWidget > ul > li:last-child:after {
  content: " ";
}
.itemAboveTitle h3 {
  margin-top: 5px;
}
.miniTopElement {
  margin: 0;
  font-size: 0.8em;
}
.assetDetails {
  list-style: none;
}
.relatedAssetThumbs {
  display: none;
}
.relatedAssetThumbs .relatedAssetContents {
  border: 1px solid lightgray;
  border-radius: 3px 3px 3px 3px;
  margin-top: -1px;
}
.relatedAssetContents {
  display: relative;
}
.relatedAssetContents .btn {
  display: none;
  position: absolute;
  right: 8px;
  top: 5px;
  z-index: 10;
}
.relatedAssetContents:hover .btn {
  display: block;
}
.expandRelated {
  color: #96a2a7;
}
/**
 * HACK HACK HACK
 * Align glyphicons with the zoom slider
 */
@-moz-document url-prefix() {
  .infoRow .imageControls .glyphicon {
    top: -18px;
  }
}
.relatedThumbContainerImage {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.relatedThumbHighlight {
  border: 2px red solid;
}
.relatedThumbToggle {
  height: 75px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  z-index: 1;
}
.relatedThumbToggle .relatedThumbContainer {
  z-index: 1;
  position: relative;
  width: 75px;
  height: 75px;
  overflow: hidden;
  padding: 0px;
}
.relatedThumbToggle .relatedThumbTitle {
  display: none;
}
.relatedThumbToggle:hover .relatedThumbContainer {
  height: 120px;
  width: 120px;
  left: -10px;
  top: -5px;
  position: absolute;
  z-index: 10;
  padding: 5px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  background-color: white;
  margin: 0px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.relatedThumbToggle:hover .relatedThumbContainer .relatedThumbContainerImage {
  display: block;
}
.relatedThumbToggle:hover .relatedThumbTitle {
  display: block;
  z-index: 20;
  background-color: white;
  width: 120px;
  left: 5px;
  top: 95px;
  position: absolute;
  padding-left: 3px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.verticalCenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#embedView > .sidebarContainer {
  padding: 20px;
}
.showDetails {
  display: none;
  margin-left: 5px;
}
.assetWidget .collapsedChild {
  width: 95%;
  border-width: 0px;
}
.assetWidget .collapsedChild .assetWidget {
  width: 95%;
}
.assetWidget .collapsedChild .assetWidget .collapsedChild {
  border-width: 0px;
  background-color: white;
}
.panel-group.nestedGroup {
  margin-bottom: 0px;
}
.tagList li a {
  padding-left: 4px;
  padding-right: 4px;
}
.thumbnailFixedSize {
  position: relative;
  width: 75px;
  height: 75px;
  overflow: hidden;
  float: left;
  margin: 3px;
  padding: 0px;
}
.thumbnailFixedSize img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.fileList img {
  display: inline;
  cursor: pointer;
}
.tiny-image {
  max-width: 75px;
  max-height: 75px;
}
.super-tiny-image {
  max-width: 50px;
  max-height: 30px;
  margin-right: 5px;
  margin-top: -6px;
  cursor: pointer;
}
/**
 * Horrible, horrible hacks that I'm telling myself is just for proof of concept
 */
.sideScrollAssets {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
.sideScrollAssets .innerDiv {
  width: 1000000px;
}
.sideScrollAssets .innerDiv > div {
  width: 285px;
  display: inline-block;
  float: left;
}
.sideScrollAssets .previewEntry ul li {
  white-space: normal;
}
.popover {
  max-width: 350px;
  width: 350px;
}
.popover iframe {
  width: 100%;
}
.popover-content ul {
  margin: 0px;
  padding: 0px;
}
.embedControl {
  width: 80%;
  display: inline;
}
.popover-content ul > li:hover {
  background-color: #eff2f3;
}
input[type='range'].zoom-range {
  -webkit-appearance: none !important;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px #333;
  background-color: #999;
  display: block;
  height: 10px;
  left: 10px;
  margin: 20px;
  display: inline;
  right: 10px;
  width: calc(100% - 150px);
}
input[type='range'].zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  -webkit-border-radius: 5px;
  background-color: #AAA;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#EEE), to(#AAA));
  border: 1px solid #999;
  height: 22px;
  width: 22px;
}
/**
 * ! handlebars templates
 */
.previewContent:hover > .removeButton {
  display: inline;
}
.marker-template img {
  max-height: 75px;
  margin-right: 5px;
}
.resultContainer {
  height: 370px;
  margin-bottom: 10px;
  -webkit-box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.2);
  box-shadow: -3px -3px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.resultContainer h5 {
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  color: #175a85;
  padding-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
}
.resultContainer h5:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
.previewCrop {
  position: relative;
  width: 240px;
  height: 150px;
  overflow: hidden;
  background-color: #d9dee2;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  vertical-align: middle;
}
.previewCrop .badge {
  position: absolute;
  right: 5px;
  top: 5px;
}
.previewCrop:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  /* vertical alignment of the inline element */
  height: 100%;
}
.previewContent {
  height: 188px;
  width: 250px;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  color: #660013;
}
.listResultContainer {
  margin: 10px;
  background-color: white;
  border-color: lightgray;
  border-radius: 10px 10px 10px 10px;
  border-width: 1px;
  border-style: solid;
}
.listResultContainer h3 {
  margin-top: 0px;
}
.listImageContainer {
  background-color: #d9dee2;
  width: 255px;
  margin: 5px;
}
.listPreviewCrop {
  width: 100px;
  overflow: hidden;
  background-color: lightgray;
  padding: 5px;
  border-radius: 10px 0px 0px 10px;
  vertical-align: middle;
  display: table-cell;
}
.listTextContainer {
  margin-top: 5px;
}
.listPreviewImage {
  max-width: 235px;
  max-height: 135px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
.previewImage {
  max-width: 235px;
  max-height: 135px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  vertical-align: middle;
}
.previewEntry ul {
  margin-left: 0px;
  padding-left: 0px;
  display: inline;
  list-style-type: none;
}
.previewEntry ul li {
  display: inline;
}
.previewEntry ul li:nth-child(n+4) {
  display: none;
}
.previewEntry ul li:nth-child(n+3):after {
  content: "";
}
.previewEntry ul li:after {
  content: ", ";
}
.previewEntry ul li:last-child:after {
  content: "";
}
ul.collectionList {
  margin-left: 0px;
  padding-left: 0px;
  display: inline;
  list-style-type: none;
}
ul.collectionList li {
  display: inline;
}
ul.collectionList li:not(:last-child):after {
  content: " > " !important;
}
ul.collectionList li:last-child:after {
  content: "";
}
/**
 * ! search results
 */
#mapPane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#mapFrame {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 56.25%;
  height: 0;
}
#advancedSearchMap > .mapWidget {
  width: 100%;
  height: 400px;
}
#galleryFrame {
  width: 100%;
  height: 600px;
}
.searchTabs {
  background-color: #d9dee2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.searchTabHeader {
  margin-bottom: -1px;
}
.advanced-search-toggle {
  border-left: 1px solid #cccccc !important;
}
.collectionSelector {
  list-style-type: none;
}
.useSuggest {
  cursor: pointer;
}
.suggestionText {
  padding-bottom: 10px;
}
/**
 * ! file handlers
 */
.infoPopover,
.canFullscreen {
  cursor: pointer;
}
.infoRow .glyphicon {
  top: 3px;
  font-size: 1.3em;
}
.infoRow {
  background-color: #d8dfe2;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  padding-top: 0px;
  margin-bottom: 0px;
}
.assetViewRow {
  background-color: #d8dfe2;
  padding-bottom: 0px;
  margin-top: 0px;
}
.excerpt {
  background-color: #d8dfe2;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.videoColumn {
  height: 480px;
}
.imageContainer {
  height: 100%;
  width: 100%;
  max-height: inherit;
}
.imageContent {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: inherit;
}
.fullscreenImageContainer {
  max-height: 550px;
}
.fixedHeightContainer {
  height: 550px;
}
.imageFullscreen {
  background-color: black;
}
.canvasFullscreen {
  background-color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hoverSlider {
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  width: 85%;
  position: absolute;
  right: 7.5%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(211, 211, 211, 0.7);
  padding: 5px;
  bottom: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: inline;
}
.hoverSlider input[type='range'] {
  width: calc(93% - 50px);
}
.hoverSlider .canFullscreen {
  top: 40%;
  position: absolute;
}
.fullscreenImageContainer:hover .hoverSlider {
  -moz-opacity: 1;
  -khtml-opacity: 1;
  -webkit-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
/**
 * 3d viewer
 */
@media (max-width: 480px) {
  .threedelementcontainer {
    height: 450px;
  }
}
@media (min-width: 481px) {
  .threedelementcontainer {
    height: 600px;
  }
}
/**
 * Box.net viewer
 */
.boxContainer {
  height: 500px;
}
.box-controls-container {
  height: 44px;
  line-height: 44px;
  top: 10;
  width: 100%;
  text-align: center;
}
.box-controls-container .controls {
  display: inline-block;
}
.box-controls-container .controls-left {
  display: block;
  position: absolute;
  left: 0;
}
.box-controls-container .controls-right {
  position: absolute;
  right: 0;
}
.box-controls-container .controls .btn {
  border: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
}
.box-controls-container .controls .page {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.box-controls-container .controls .page-display {
  width: auto;
  background: transparent;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 10pt;
  line-height: 0;
  margin: 0 5px 0;
  vertical-align: middle;
  text-align: center;
}
.box-controls-container .controls .page-input {
  position: absolute;
  width: 100%;
  height: 24px;
  line-height: 24px;
  top: 11px;
  left: 0;
  display: none;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 10pt;
  text-align: center;
  vertical-align: middle;
}
.box-controls-container .controls .page-input:focus {
  outline: 0;
  border: 1px solid #aaa;
  box-shadow: #ccc 0 0 1px inset;
}
.box-controls-container .controls .btn,
.box-controls-container .controls {
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  margin: 7px 5px 0;
}
.box-controls-container .controls .icon {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
}
.box-controls-container .controls .btn .icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.box-controls-container .controls .btn {
  cursor: pointer;
}
.box-controls-container .controls .btn:disabled {
  cursor: default;
}
/**
 * DICOM viewer
 */
.dicomContainer {
  height: 480px;
}
/**
 * ! Admin
 */
.autoTruncate {
  white-space: nowrap;
  overflow: hidden;
  /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
}
.fieldsetSection {
  border: 1px solid lightgray;
  padding: 0 1.4em 1.4em 1.4em;
  margin: 0 0 1.5em 0;
}
.fieldsetSection legend {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
}
/**
 * ! Collection List
 */
.collectionList ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.collectionList ul li {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.collectionList ul li:last-child {
  border: none;
}
.lightboxContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: none;
  z-index: 2000;
}
.lightboxCloseButton {
  position: fixed;
  cursor: pointer;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 2.2em;
  padding-left: 0.6em;
  padding-right: 0.6em;
}
.lightboxAsset {
  position: fixed;
  top: calc(5vh);
  left: calc(5vw);
  width: calc(90vw);
  max-height: calc(90vh) !important;
  z-index: 2001;
}
.triggerLightbox {
  cursor: pointer;
}


.textareaView {
  width: 100%;
  overflow-wrap: break-word;
}